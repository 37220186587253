// export const API_URL = "https://615e-60-254-78-144.ngrok-free.app";
// export const API_URL = "https://0b9b-116-75-240-169.ngrok-free.app/";
// export const API_URL = "http://65.2.189.208:3001/api/v1/";
// export const API_URL = "http://13.200.55.131:3001/api/v1/";
export const API_URL = "http://13.200.55.131:3001/api/v1/";
export const API_URL_STAGING = "https://back.rainmaker.co.in/api/v1/";
export const API_URL_SOCKET = "http://13.200.237.10:4000/";
// export const API_URL_Two = "http://13.200.55.131:8081/api/v1/";
// export const SECRENT_KEY = ""
// export const API_URL = "https://lms.vector3.digital/api/v1/";
// export const API_URL_Two = "https://lms.vector3.digital/api/v1/";
