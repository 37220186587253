import axios from "axios";
import { API_URL_STAGING } from "../pages/constants/Statics";

const instance = axios.create({
  baseURL: API_URL_STAGING,
});

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

if (localStorage.getItem("accessToken")) {
  const JWT_token = localStorage.getItem("accessToken");
  instance.defaults.headers.common["Authorization"] = `Bearer ${JWT_token}`;
}

export const updateAccessToken = (accessToken) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // Handle 401 error
      localStorage.removeItem("rainDataToken");
      localStorage.removeItem("accessToken");
      // localStorage.clear();
      window.location.href = "/";
    }
    // if (error.response && error.response.status === 404) {
    //   window.location.href = "/page-not-found";
    // }

    // Return any error not to be handled
    return Promise.reject(error);
  }
);

export default instance;
